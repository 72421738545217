(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:ChampionshipClassification
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('ChampionshipClassification', ChampionshipClassification);

  function ChampionshipClassification($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/championships/:id/classification', {id: '@_id'});
  }
}());
